import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import WhySurplus from "../components/auctions/why-surplus"
import AuctionSummary from "../components/auctions/auction-summary"
import Clients from "../components/auctions/clients"
import Breadcrumb from "../components/layout/breadcrumb"

class Auctions extends React.Component {
  render() {
    const { data } = this.props

    var futureAuctions = data.allAuction.edges
      .filter(({ node }) => {
        let endDate = new Date(node.endDate.seconds * 1000).setHours(0, 0, 0, 0)
        if (endDate >= new Date().setHours(0, 0, 0, 0)) return node
      })
      .reverse()
    var pastAuctions = data.allAuction.edges
      .filter(({ node }) => {
        let endDate = new Date(node.endDate.seconds * 1000).setHours(0, 0, 0, 0)
        if (endDate < new Date().setHours(0, 0, 0, 0)) return node
      })
      .slice(0, 15)

    return (
      <>
        <Layout>
          <SEO
            title={`Used Laboratory Auction: Pharmaceutical & Scientific Equipment`}
            description={`At Surplus Solutions, our online auctions provide our clients with the ideal balance between maximum returns and quick turnaround. We work closely with our clients to determine the specific type of auction to best meet their needs. Contact us today to learn more about our online auctions.`}
          />

          <Breadcrumb paths={[{ name: "Auctions", path: "auctions" }]} />

          <div className="row column">
            <h1 className="headings">
              Save Big on Used Lab, Processing, and Packaging Equipment in our
              Live Online Auctions
            </h1>
            <p>
              Our auctions give you the opportunity to receive great deals on
              used lab, processing, and packaging equipment from major
              facilities in the area. Whether you are looking to replace one
              piece of equipment, or outfit an entire facility, our online
              auctions are a perfect opportunity to see how we can help. If you
              have any questions, please contact us at{" "}
              <a href="mailto:auctions@ssllc.com">auctions@ssllc.com</a>.
            </p>

            <a
              className="button secondary tiny wide caps "
              style={{ float: "right" }}
              href="/timed-auctions/#!/login/"
            >
              Login to My Account
            </a>
            <br />
            <br />

            <h2 className="headings h1">Upcoming Auctions</h2>

            {futureAuctions.map(({ node }) => (
              <div key={node.id}>
                <AuctionSummary {...node} />
              </div>
            ))}

            {futureAuctions.length === 0 ? (
              <div>
                No upcoming auctions at this time
                <br />
                <br />
                <br />
              </div>
            ) : null}

            <h2 className="headings h1">Past Auctions</h2>

            {pastAuctions.map(({ node }) => (
              <div key={node.id}>
                <AuctionSummary {...node} />
              </div>
            ))}
          </div>

          <WhySurplus />

          <Clients />
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query {
    allAuction(sort: { order: DESC, fields: startDate___seconds }) {
      totalCount
      edges {
        node {
          title
          blurb
          startDate {
            seconds
          }
          endDate {
            seconds
          }
          slug
          summaryImageUrl
        }
      }
    }
  }
`

export default Auctions
