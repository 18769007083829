import React from "react"

import ONE_ICON from "../../images/content/client-logos/allergan-grey.jpg"
import TWO_ICON from "../../images/content/client-logos/biodel-grey.jpg"
import THREE_ICON from "../../images/content/client-logos/exosome-grey.jpg"
import FOUR_ICON from "../../images/content/client-logos/forum-grey.jpg"
import FIVE_ICON from "../../images/content/client-logos/lantheus-grey.jpg"
import SIX_ICON from "../../images/content/client-logos/mylan-grey.jpg"
import SEVEN_ICON from "../../images/content/client-logos/lexicon-grey.jpg"
import EIGHT_ICON from "../../images/content/client-logos/sangart-grey.jpg"
import NINE_ICON from "../../images/content/client-logos/amea-grey.jpg"
import TEN_ICON from "../../images/content/client-logos/massbio-grey.jpg"
import ELEVEN_ICON from "../../images/content/client-logos/mdna-grey.jpg"
import TWELVE_ICON from "../../images/content/client-logos/naa-grey.jpg"



const Clients = () => {
  return (
    <div>
      <div className="row column mb9">
        <h2 className="headings h1">Clients We&apos;ve Worked With</h2>
        <div className="row xsmall-up-2 small-up-3 medium-up-4 client-items">
          <div className="column">
            <img
              src={ONE_ICON}
              alt="Allergan Logo"
            />
          </div>
          <div className="column">
            <img
              src={TWO_ICON}
              alt="Biodel Logo"
            />
          </div>
          <div className="column">
            <img
              src={THREE_ICON}
              alt="Exosome Sciences Logo"
            />
          </div>
          <div className="column">
            <img
              src={FOUR_ICON}
              alt="Forum Pharmaceuticals Logo"
            />
          </div>
          <div className="column">
            <img
              src={FIVE_ICON}
              alt="Lantheus Medial Imaging Logo"
            />
          </div>
          <div className="column">
            <img
              src={SIX_ICON}
              alt="Mylan Logo"
            />
          </div>
          <div className="column">
            <img
              src={SEVEN_ICON}
              alt="Lexicon Pharmaceuticals Logo"
            />
          </div>
          <div className="column">
            <img
              src={EIGHT_ICON}
              alt="Sangart Logo"
            />
          </div>
          <div className="column">
            <img
              src={NINE_ICON}
              alt="AMEA Logo"
            />
          </div>
          <div className="column">
            <img
              src={TEN_ICON}
              alt="MassBio Logo"
            />
          </div>
          <div className="column">
            <img
              src={ELEVEN_ICON}
              alt="MDNA Logo"
            />
          </div>
          <div className="column">
            <img
              src={TWELVE_ICON}
              alt="NAA Auctioneer Logo"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clients
