import React from "react"


import WHITE_STAR_ICON from "../../images/template/icon-star-white.png"
import KEYBOARD_ICON from "../../images/template/icon-keyboard-white.png"
import GLOBE_ICON from "../../images/template/icon-globe-white.png"

const WhySurplus = () => {
  return (
    <>
      <section className="bg-blue pt8 pb5 mb9">
        <div className="row">
          <h2 className="center h1 white">Why Surplus Auctions?</h2>
          <div className="card-container">
            <div className="card">
              <img src={WHITE_STAR_ICON} alt="Star Icon" />
              <strong>Great Deals</strong>
              Our used surplus auctions not only ensure that you are getting
              quality used lab, process, and packing equipment, but that you are
              getting them for a great value.
            </div>
            <div className="card">
              <img
                src={KEYBOARD_ICON}
                alt="Keyboard Icon"
              />
              <strong>Variety</strong>
              If you are looking for a specific piece of equipment, our used
              surplus equipment auctions are a great place to find it due to a a
              frequent turnover of pieces. You'll definitely find what you are
              looking for.
            </div>
            <div className="card">
              <img
                src={GLOBE_ICON}
                alt="Globe Icon"
              />
              <strong>Convenience</strong>
              Online auctions allow you to bid on items from anywhere in the
              world. Travel is only required to physically inspect the selected
              pieces of surplus lab, processing, or packing equipment.
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WhySurplus
