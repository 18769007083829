import React from "react"
import moment from "moment"
import { Link } from 'gatsby'

moment().format()

const AuctionSummary = (props) => {

  var startDate = new Date(props.startDate.seconds * 1000);
  var endDate = new Date(props.endDate.seconds * 1000);
  let startDateMoment = moment(startDate)
  let endDateMoment = moment(endDate)

  var options = { timeStyle: 'long', dateStyle: 'long'};

  return (
    <>
      <div className="row">
        <div className="medium-6 columns product-image">
          <a
            title={props.title}
            href={`/auction${props.slug}`}
          >
            <img
              alt={props.title}
              className="border"
              src={props.summaryImageUrl}
            />
          </a>
        </div>
        <div className="medium-6 columns pt6">
          <h4 className="headings xshort">{props.title} </h4>
          <p>
            Start: {startDateMoment.format("LLL")}
            <br />
            End: {endDateMoment.format("LLL")}
          </p>
          <p>
          {props.description}
          </p>
          <p className="mt6">
            
         
              <Link className="button secondary tiny wide caps" to={`/auction${props.slug}`}>Learn More</Link>
            
          </p>
        </div>
        <hr />
      </div>
    </>
  )
}

export default AuctionSummary
